import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Publish from './pages/Publish';
import Video from './pages/Video';
import SekizinciSinifWorksheet from './pages/SekizinciSinifWorksheet';
import YedinciSinifWorksheet from './pages/YedinciSinifWorksheet';
import AltinciSinifWorksheet from './pages/AltinciSinifWorksheet';
import BesinciSinifWorksheet from './pages/BesinciSinifWorksheet';
import UcuncuSinifWorksheet from './pages/UcuncuSinifWorksheet';
import DorduncuSinifWorksheet from './pages/DorduncuSinifWorksheet';



import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import './App.css';

function App() {
  return (
    <div>
    <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/Publish' element={<Publish />} />
          <Route path='/Video' element={<Video />} />
          <Route path='UcuncuSinifWorksheet' element={<UcuncuSinifWorksheet/>} />
          <Route path='DorduncuSinifWorksheet' element={<DorduncuSinifWorksheet/>} />
          <Route path='BesinciSinifWorksheet' element={<BesinciSinifWorksheet/>} />
          <Route path='AltinciSinifWorksheet' element={<AltinciSinifWorksheet/>} />
          <Route path='YedinciSinifWorksheet' element={<YedinciSinifWorksheet/>} />
          <Route path='SekizinciSinifWorksheet' element={<SekizinciSinifWorksheet/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

