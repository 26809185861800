import React from 'react';
import Config from '../Config.json';
import {Helmet} from 'react-helmet';
import { NavLink, Link } from 'react-router-dom';
const TITLE = "Hakkımızda | " + Config.SITE_TITLE;
const DESC = "Parlak bir gelecek için, sağlam temeller inşa ediyoruz.";
const CANONICAL = Config.SITE_DOMAIN + "/";


class About extends React.Component{
    render(){
        return(
            <main>
              <Helmet>
          <title>{TITLE}</title>
          <link rel="canonical" href="{CANONICAL}" />
          <meta name="description" content="{DESC}"/>
          <meta name="theme-color" content={Config.THEME_COLOR}/>
        </Helmet>
        <div className="container-fluid bg-light position-relative shadow">
            <nav
              className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0 px-lg-5"
            >
              
                <a href="/" className="logo">
                  <img src="img/paccanvas.png" alt=""/>
                </a>
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
              >
                <div className="navbar-nav font-weight-bold mx-auto py-0">
        <a>
          <NavLink to="/" className="nav-item nav-link" >Anasayfa</NavLink>
        </a>
        <a>
          <NavLink to="/About" className="nav-item nav-link">Hakkımızda</NavLink>
        </a>
        <a>
          <NavLink to="/Publish" className="nav-item nav-link">Dijital</NavLink>
        </a>
        <a>
          <NavLink to="/Video" className="nav-item nav-link">Video</NavLink>
        </a>
        <a>
          <NavLink to="/Contact" className="nav-item nav-link">İletişim</NavLink>
        </a>
                </div>
                <div className="meb">
                <img src="img/meb.png" alt="meb-logo"/>
            </div>
            </div>
            </nav>
          </div>
<div className="container-fluid bg-primary mb-5">
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{minHeight: 400}}
      >
        <h3 className="display-3 font-weight-bold text-white">Hakkımızda</h3>
        <div className="d-inline-flex text-white">
          <p className="m-0"><a className="text-white" href="index.html">Anasayfa</a></p>
          <p className="m-0 px-2">/</p>
          <p className="m-0">Hakkımızda</p>
        </div>
      </div>
    </div>
    <div className="container-fluid py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <img
              className="img-fluid rounded mb-5 mb-lg-0"
              src="img/logo.png"
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <p className="section-title pr-5">
              <span className="pr-2">PAC Akademi Nedir?</span>
            </p>
            <h3 className="mb-4">Deneyimli eğitmenler, bire bir etütler, 8 kişilik özel sınıflar, PAC imzalı kaynaklarımız ile çocuklarımıza ayrıcalıklı dil eğitiminin tüm avantajlarını sunuyoruz.</h3>
              <div className="col-12 col-md-12">
                <ul className="list-inline m-6">
                  <li className="py-2 border-top border-bottom">
                    <i className="fa fa-check text-primary mr-3"></i>İlgi Çekici Müfredat:
                    Çocuklara yönelik İngilizce kursları eğlenceli ve etkileşimli unsurlarla tasarlanmıştır. Öğrenmeyi keyifli hale getirmek için oyunlar, şarkılar ve renkli görseller gibi yaşa uygun materyaller içerirler.
                    Bu yaklaşım çocukları derslere katılma konusunda heyecanlandırır ve öğrenmeye motive eder.
                  </li>
                  <li className="py-2 border-bottom">
                    <i className="fa fa-check text-primary mr-3"></i>Deneyimli Eğitmenler:
                    Çocuklara yönelik İngilizce kurslarındaki nitelikli eğitmenler, genç öğrencilerin benzersiz ihtiyaçlarını anlar.
                    Çocukların öğrenme stillerine hitap eden etkili öğretim yöntemleri kullanarak derslerin ilgi çekici, anlaşılır ve eğlenceli olmasını sağlarlar.
                  </li>
                  <li className="py-2 border-bottom">
                    <i className="fa fa-check text-primary mr-3"></i>Küçük Sınıf Mevcutları:
                    Bu kurslar genellikle küçük sınıf mevcutlarına sahiptir ve eğitmenlerin bireysel ilgi göstermesine ve her çocuğun özel ihtiyaçlarını karşılamasına olanak tanır.
                    Bu kişiselleştirilmiş yaklaşım, çocukların kendi hızlarında ilerlemelerine ve dil becerilerine güvenmelerine yardımcı olur.
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid bg-secondary text-white mt-5 py-5 px-sm-3 px-md-5"
          >
            <div className="row pt-5">
              <div className="col-lg-3 col-md-6 mb-5">
                <a
                  className="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0"
                  style={{ fontsize: 40, lineheight: 40 }}
                >
                  <i className="logo">
                    <img src="img/logo.png" />
                  </i>
                </a>
                <p>
                  Günümüzün küreselleşen dünyasında İngilizce iletişim kurabilmek giderek daha önemli hale geldi. İngilizce sadece bir dil değildir; fırsatlara, bilgiye ve bağlantıya açılan bir kapıdır.
                  Eğitimciler olarak çocuklarımıza 21. yüzyılda başarılı olmaları için ihtiyaç duydukları araçları sağlamanın önemini biliyor ve çalışıyoruz.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mb-5">
                <h3 className="text-primary mb-4">İletişime Geçin</h3>
                <div className="d-flex">
                  <h4 className="fa fa-map-marker-alt text-primary"></h4>
                  <div className="pl-3">
                    <h5 className="text-white">Adres</h5>
                    <p>Taşköprü Mahallesi, Şeyhzade Caddesi. Ahmet Efendi Camii karşısı Merkez/Yozgat</p>
                  </div>
                </div>
                <div className="d-flex">
                  <h4 className="fa fa-envelope text-primary"></h4>
                  <div className="pl-3">
                    <h5 className="text-white">E-Posta</h5>
                    <p>info@pacakademi.com</p>
                  </div>
                </div>
                <div className="d-flex">
                  <h4 className="fa fa-phone-alt text-primary"></h4>
                  <div className="pl-3">
                    <h5 className="text-white">Telefon</h5>
                    <p>+90 546 280 18 84</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5">
                <h3 className="text-primary mb-4">Hızlı Bağlantı</h3>
                <div className="d-flex flex-column justify-content-start">
                <a> <NavLink to="/" className="text-white">Anasayfa</NavLink></a>
                  <a> <NavLink to="/About" className="text-white">Hakkımızda</NavLink></a>
                  <a> <NavLink to="/Publish" className="text-white">Dijital Kaynak</NavLink></a>
                  <a> <NavLink to="/Contact" className="text-white">İletişim</NavLink></a>
                  <a> <NavLink to="https://www.instagram.com/professionalacademycenter" target="_blank" className="text-white">Instagram</NavLink></a>
                  <a> <NavLink to="https://wa.me/5462801884" className="text-white">Whatsapp</NavLink></a>

                </div>
              </div>

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1553293.076832151!2d30.598398329941947!3d40.50234627119469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4080710a65301729%3A0x151a3f7e5217b1af!2sPAC%20Academy!5e0!3m2!1str!2str!4v1696641834881!5m2!1str!2str" width="350" height="300" style={{ border: 0 }} position="text-center" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div
              className="container-fluid pt-5"
            >
              <p className="m-0 text-center text-white">
                &copy;
                <a className="text-primary font-weight-bold">PAC Akademi</a>.
                Tüm Hakları Saklıdır.

                <br />designed by
                <a> <Link to="https://404yz.com" target="_blank">404yz </Link>
                </a>
              </p>
            </div>
          </div><a href="#" className="btn btn-primary p-3 back-to-top"
          ><i className="fa fa-angle-double-up"></i></a>
</main>
        )
    }
}

export default About;