import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import Config from '../Config.json';
import {Helmet} from 'react-helmet';
const TITLE = "Anasayfa | " + Config.SITE_TITLE;
const DESC = "Parlak bir gelecek için, sağlam temeller inşa ediyoruz.";
const CANONICAL = Config.SITE_DOMAIN + "/";


class Home extends React.Component{
    render() {
       return <main>
        <Helmet>
          <title>{TITLE}</title>
          <link rel="canonical" href="{CANONICAL}" />
          <meta name="description" content="{DESC}"/>
          <meta name="theme-color" content={Config.THEME_COLOR}/>
        </Helmet>
        <div className="container-fluid bg-light position-relative shadow">
            <nav
              className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0 px-lg-5"
            >
              
                <a href="/" className="logo">
                  <img src="img/paccanvas.png" alt=""/>
                </a>
              <button
                type="button"
                className="navbar-toggler"
                data-toggle="collapse"
                data-target="#navbarCollapse"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
              >
                <div className="navbar-nav font-weight-bold mx-auto py-0">
        <a>
          <NavLink to="/" className="nav-item nav-link" >Anasayfa</NavLink>
        </a>
        <a>
          <NavLink to="/About" className="nav-item nav-link">Hakkımızda</NavLink>
        </a>
        <a>
          <NavLink to="/Publish" className="nav-item nav-link">Dijital</NavLink>
        </a>
        <a>
          <NavLink to="/Video" className="nav-item nav-link">Video</NavLink>
        </a>
        <a>
          <NavLink to="/Contact" className="nav-item nav-link">İletişim</NavLink>
        </a>
                </div>
                <div className="meb">
                <img src="img/meb.png" alt="meb-logo"/>
            </div>
            </div>
            </nav>
          </div>
        
       <div className="container-fluid bg-primary px-0 px-md-5 mb-5">
 
         <div className="row align-items-center px-3">
           <div className="col-lg-6 text-center text-lg-left">
             <h1 className="display-3 font-weight-bold text-white">
             Parlak bir gelecek için, sağlam temeller inşa ediyoruz.
             </h1>
             <a> <Link to="/contact" className="btn btn-secondary mt-1 py-3 px-5">İletişim</Link></a>
             <a> <Link to="/Publish" className="btn btn-secondary mt-1 py-3 px-5">Dijital Kaynaklar</Link></a>
           </div>
           
           
           <div className="col-lg-6 text-center text-lg-right">
             <img className="img-fluid mt-5" src="img/header.png" alt="" />
           </div>
         </div>
       </div>
       
       <div className="container-fluid pt-5">
         <div className="container pb-3">
           <div className="row">
             <div className="col-lg-4 col-md-6 pb-1">
               <div
                 className="d-flex bg-light shadow-sm border-top rounded mb-4"
                 style={{padding: "30px"}}
               >
                 <i
                   className="flaticon-035-table h1 font-weight-normal text-primary mb-3"
                 ></i>
                 <div className="pl-4">
                   <h4>Özel Sınıflar</h4>
                   <p className="m-0">
                     Bireysel ilgi ve öğrenme takibini kolaylaştıran materyal donanımlı 9 kişilik sınıflar.
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6 pb-1">
               <div
                 className="d-flex bg-light shadow-sm border-top rounded mb-4"
                 style={{padding: "30px"}}
               >
                 <i
                   className="flaticon-033-blocks h1 font-weight-normal text-primary mb-3"
                 ></i>
                 <div className="pl-4">
                   <h4>Kelime Ezberi</h4>
                   <p className="m-0">
                     Çocuklarımızın yaşıtlarının çok ötesine geçebilmeleri adına özel kelime ezberleri.
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6 pb-1">
               <div
                 className="d-flex bg-light shadow-sm border-top rounded mb-4"
                 style={{padding: "30px"}}
               >
                 <i
                   className="flaticon-023-girl h1 font-weight-normal text-primary mb-3"
                 ></i>
                 <div className="pl-4">
                   <h4>Speaking Aktiviteleri</h4>
                   <p className="m-0">
                     Yabancı eğitmenlerle dil yeteneklerini pekiştirmek için konuşma kulüpleri.
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6 pb-1">
               <div
                 className="d-flex bg-light shadow-sm border-top rounded mb-4"
                 style={{padding: "30px"}}
               >
                 <i
                   className="flaticon-047-backpack h1 font-weight-normal text-primary mb-3"
                 ></i>
                 <div className="pl-4">
                   <h4>Ücretsiz Setler</h4>
                   <p className="m-0">
                     Kurs dışı eğitimin devamlılığını için PAC Akademi imzalı ücretsiz eğitim setleri.
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6 pb-1">
               <div
                 className="d-flex bg-light shadow-sm border-top rounded mb-4"
                 style={{padding: "30px"}}
               >
                 <i
                   className="flaticon-011-mat h1 font-weight-normal text-primary mb-3"
                 ></i>
                 <div className="pl-4">
                   <h4>MEB Uyumlu Eğitim</h4>
                   <p className="m-0">
                     Öğrencilerimizin müfredatına uygun ilerliyor ve etüt çalışmalarıyla destekliyoruz.
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6 pb-1">
               <div
                 className="d-flex bg-light shadow-sm border-top rounded mb-4"
                 style={{padding: "30px"}}
               >
                 <i
                   className="flaticon-014-blackboard h1 font-weight-normal text-primary mb-3"
                 ></i>
                 <div className="pl-4">
                   <h4>Ders Videoları</h4>
                   <p className="m-0">
                     Çalışma ulaşılabilirliğini arttırdık ve videolarımızla online olarak destek oluyoruz.
                   </p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div className="container-fluid pt-5">
         <div className="container">
           <div className="text-center pb-2">
             <p className="section-title px-5">
               <span className="px-2">Sınıflar</span>
             </p>
             <h1 className="mb-4">Sınıf Avantajlarımız</h1>
           </div>
           <div className="row">
             <div className="col-lg-4 mb-5">
               <div className="card border-0 bg-light shadow-sm pb-2">
                 <img className="card-img-top mb-2" src="img/class-1.jpg" alt="" />
                 <div className="card-body text-center">
                   <h4 className="card-title">İlkokul Sınıfları</h4>
                   <p className="card-text">
                     İlkokul öğrencilerimizin İngilizce ile tanışması ve gelecekleri için
                     en büyük ilk adımı atmalarını sağlıyoruz.
                   </p>
                 </div>
                 <div className="card-footer bg-transparent py-4 px-5">
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">2-3-4. Sınıf</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Sınıf Mevcudu</strong>
                     </div>
                     <div className="col-6 py-1">8 Kişi</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Ders Saatleri</strong>
                     </div>
                     <div className="col-6 py-1">1 Saat/Hafta</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>2. Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">9:30-10:50</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>3. Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">11:20-12:40</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>4. Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">13:00-14:30</div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 mb-5">
               <div className="card border-0 bg-light shadow-sm pb-2">
                 <img className="card-img-top mb-2" src="img/class-2.jpg" alt="" />
                 <div className="card-body text-center">
                   <h4 className="card-title">Ortaokul Sınıfları</h4>
                   <p className="card-text">
                     Ortaokul öğrencilerimizin MEB müfredatına uygun olarak
                     derslerine katkıda bulunuyor, en iyi şekilde pekiştirmelerini sağlıyoruz.
                   </p>
                 </div>
                 <div className="card-footer bg-transparent py-4 px-5">
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">5-6-7. Sınıflar</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Sınıf Mevcudu</strong>
                     </div>
                     <div className="col-6 py-1">8 Kişi</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Ders Saatleri</strong>
                     </div>
                     <div className="col-6 py-1">2 Saat/Hafta</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>5. Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">14:40-16:00</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>6. Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">16:00-17:40</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>7. Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">Çarş.-Cuma</div>
                   </div>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 mb-5">
               <div className="card border-0 bg-light shadow-sm pb-2">
                 <img className="card-img-top mb-2" src="img/class-3.jpg" alt="" />
                 <div className="card-body text-center">
                   <h4 className="card-title">LGS</h4>
                   <p className="card-text">
                     LGS sürecindeki öğrencilerimizi Yeni Nesil Soru Çözümleri ve aktivitelerle İngilizce öğrenimlerini destekliyoruz.
                   </p>
                 </div>
                 <div className="card-footer bg-transparent py-4 px-5">
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Sınıflar</strong>
                     </div>
                     <div className="col-6 py-1">8. Sınıf</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Dersler</strong>
                     </div>
                     <div className="col-6 py-1">MAT-İNG</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Sınıf Mevcudu</strong>
                     </div>
                     <div className="col-6 py-1">8 Kişi</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Etüt Çalışması</strong>
                     </div>
                     <div className="col-6 py-1">Birebir Etüt</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Etüt Saatleri</strong>
                     </div>
                     <div className="col-6 py-1">16:00 - 19:10</div>
                   </div>
                   <div className="row border-bottom">
                     <div className="col-6 py-1 text-right border-right">
                       <strong>Ek Materyal</strong>
                     </div>
                     <div className="col-6 py-1">Sınırsız PDF</div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>

       <div className="container-fluid pt-5">
         <div className="container">
           <div className="text-center pb-2">
             <p className="section-title px-5">
               <span className="px-2">Eğitim Kaynakları</span>
             </p>
             <h1 className="mb-4">Ürünlerimiz</h1>
           </div>
         </div>
        </div>
        
        <div className="product">
  <div className="row no-gutters">
    <div className="col-lg-6">
      <div className="product-slider">
        <div className="product-slide"> <img src='./img/storybook.png'width="500" height={450} ></img></div>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="product-content p-4 p-md-5 h-100">
        <h3 className="product-title">Animated Stories Hikaye Kitabı</h3>
        <div className="product-category">İlkokul Seviyesi İçin Uygundur</div>
        <div className="product-price">İlkokul Kelime Kartı Hediyesidir</div>
        <div className="product-description">
          <p>Kitap 5 ayrı hikayeden oluşmakta; her hikayenin sonunda
            10 adet sorudan oluşan test, hikayeleri dinleyebilmek ve cevap anahtarlarına ulaşmak için QR Kod bulunmaktadır.
            2,3 ve 4. sınıflar için uygundur.</p>
        </div>

      </div>
    </div>
  </div>
</div>

<div className="product">
  <div className="row no-gutters">
    <div className="col-lg-6">
      <div className="product-slider">
        <div className="product-slide"> <img src='./img/testbook.png'width="500" height={450} ></img></div>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="product-content p-4 p-md-5 h-100">
        <h3 className="product-title">LGS Vocabulary Test Kitabı</h3>
        <div className="product-category">8. Sınıf Seviyesi İçin Uygundur</div>
        <div className="product-price">Ortaokul Kelime Kartı Hediyesidir</div>
        <div className="product-description">
          <p>Kitabımız, LGS sınavlarında karşılaşılabilecek her türlü soru tipini kapsayan geniş bir yelpazeye sahiptir.
            Yeni Nesil Soru Çözümü odaklı, 10 Ünite Testi ve 4 Sarmal Test içeriğine sahiptir.
            Cevap anahtarına ulaşmak için QR kod bulunmaktadır. LGS odaklı sorular içermektedir.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="product">
  <div className="row no-gutters">
    <div className="col-lg-6">
      <div className="product-slider">
        <div className="product-slide"> <img src='./img/kutu.png'width="500" height={450} ></img></div>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="product-content p-4 p-md-5 h-100">
        <h3 className="product-title">PAC Akademi Kelime Kartları</h3>
        <div className="product-category">İlkokul ve Ortaokul Seviyeleri</div>
        <div className="product-price">İlkokul ₺350 - Ortaokul 400₺</div>
        <div className="product-description">
          <p>Kelime kartlarımız MEB temeline dayanır, her sınıf için ünite bazlı kelimeler içermektedir.
            <ul><strong>İlkokul Kelime Kartları:</strong>
              <li>
              2. Sınıflar için 200 adet
              </li>
              <li>
              3. Sınıflar için 200 adet
              </li>
              <li>
              4. Sınıflar için 355 adet
              </li>
            <strong>Ortaokul Kelime Kartları:</strong>
              <li>
              5. Sınıflar için 300 adet
              </li>
              <li>
              6. Sınıflar için 310 adet
              </li>
              <li>
              7. Sınıflar için 480 adet
              </li>
              <li>
              8. Sınıflar için 500 adet
              </li>
            </ul>
          </p>
        </div>
        <a href="https://wa.me/5462801884" target="_blank" rel="norefferer">
        <button className="product-button btn btn-primary">SATIN AL</button>
        </a>
      </div>
    </div>
  </div>
</div>

         
       <div className="container-fluid text-center pt-5">
         <div className="container">
           <div className="text-center pb-2">
             <p className="section-title px-5">
               <span className="px-2">Referanslarımız</span>
             </p>
             <h1 className="mb-4">Değerli Velilerimiz</h1>
           </div>
           <div className="row">
           </div>
         </div>
       </div>
       <div className="container mt-5 mb-5">
 
       <div className="container mt-5 mb-5">
       <div className="row g-2">
           <div className="col-md-4">
               <div className="card p-3 text-center px-4">
                   <div className="user-image">
                       <img src="img/user.png" className="rounded-circle" width="80"
                           alt="testimonal"/>
                   </div>
                   <div className="user-content">
                       <h5 className="mb-0">Celik Öztürk</h5>
                       <p>Özellikle ziya hocam ve diğer öğretmenlerimiz çok güler yüzlü ve kaliteli ingilizce yi eğlenceli bir şekilde öğretmenlerine hayran kaldım doğrusu</p>
                   </div>
                   <div className="ratings">
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                   </div>
               </div>
           </div>
           <div className="col-md-4">
               <div className="card p-3 text-center px-4">
                   <div className="user-image">
           <img src="img/user.png" className="rounded-circle" width="80"
                           alt="testimonal"/>
                   </div>
                   <div className="user-content">
                       <h5 className="mb-0">Melike Nur Boztepe</h5>
                       <p>Çocuklar için oldukça eğlenceli ve verimli bir kurs merkezi. Kısa sürede İngilizce konuşmaları gerçekten çok şaşırtıcıydı</p>
                   </div>
                   <div className="ratings">
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                   </div>
               </div>
           </div>
           <div className="col-md-4">
               <div className="card p-3 text-center px-4">
                   <div className="user-image">
           <img src="img/user.png" className="rounded-circle" width="80"
                           alt="testimonal"/>
                   </div>
                   <div className="user-content">
                       <h5 className="mb-0">Dilanur Demirtaş</h5>
                       <p>Yozgat'taki en kaliteli İngilizce kurs merkezi..tartışmasız..kullandıkları sistemle İngilizce öğrenmeyen kalmaz</p>
                   </div>
                   <div className="ratings">
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                   </div>
               </div>
           </div>
       <div className="col-md-4">
               <div className="card p-3 text-center px-4">
                   <div className="user-image">
           <img src="img/user.png" className="rounded-circle" width="80"
                           alt="testimonal"/>
                   </div>
                   <div className="user-content">
                       <h5 className="mb-0">Yasin Yılmaz</h5>
                       <p>Yozgat’ta gördüğüm en iyi İngilizce kurs merkezi başarılarınızın devamını diliyorum</p>
                   </div>
                   <div className="ratings">
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                   </div>
               </div>
           </div>
       <div className="col-md-4">
               <div className="card p-3 text-center px-4">
                   <div className="user-image">
           <img src="img/user.png" className="rounded-circle" width="80"
                           alt="testimonal"/>
                   </div>
                   <div className="user-content">
                       <h5 className="mb-0">Hüseyin Ülker</h5>
                       <p>Eğitici kadrosu olarak gerçekten çok kaliteliler. İngilizceyi eğlenceli ve anlayarak öğreten bir kurum.</p>
                   </div>
                   <div className="ratings">
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                   </div>
               </div>
           </div>
       <div className="col-md-4">
               <div className="card p-3 text-center px-4">
                   <div className="user-image">
           <img src="img/user.png" className="rounded-circle" width="80"
                           alt="testimonal"/>
                   </div>
                   <div className="user-content">
                       <h5 className="mb-0">Hande Aydın</h5>
                       <p>Yozgatın en iyisi 😎</p>
                   </div>
                   <div className="ratings">
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                       <i className="fa fa-star"></i>
                   </div>
               </div>
           </div>
       </div>
   </div>
 </div>
 <div className="container-fluid bg-secondary text-white mt-5 py-5 px-sm-3 px-md-5"
          >
            <div className="row pt-5">
              <div className="col-lg-3 col-md-6 mb-5">
                <a
                  className="navbar-brand font-weight-bold text-primary m-0 mb-4 p-0"
                  style={{ fontsize: 40, lineheight: 40 }}
                >
                  <i className="logo">
                    <img src="img/logo.png" />
                  </i>
                </a>
                <p>
                  Günümüzün küreselleşen dünyasında İngilizce iletişim kurabilmek giderek daha önemli hale geldi. İngilizce sadece bir dil değildir; fırsatlara, bilgiye ve bağlantıya açılan bir kapıdır.
                  Eğitimciler olarak çocuklarımıza 21. yüzyılda başarılı olmaları için ihtiyaç duydukları araçları sağlamanın önemini biliyor ve çalışıyoruz.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mb-5">
                <h3 className="text-primary mb-4">İletişime Geçin</h3>
                <div className="d-flex">
                  <h4 className="fa fa-map-marker-alt text-primary"></h4>
                  <div className="pl-3">
                    <h5 className="text-white">Adres</h5>
                    <p>Taşköprü Mahallesi, Şeyhzade Caddesi. Ahmet Efendi Camii karşısı Merkez/Yozgat</p>
                  </div>
                </div>
                <div className="d-flex">
                  <h4 className="fa fa-envelope text-primary"></h4>
                  <div className="pl-3">
                    <h5 className="text-white">E-Posta</h5>
                    <p>info@pacakademi.com</p>
                  </div>
                </div>
                <div className="d-flex">
                  <h4 className="fa fa-phone-alt text-primary"></h4>
                  <div className="pl-3">
                    <h5 className="text-white">Telefon</h5>
                    <p>+90 546 280 18 84</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-5">
                <h3 className="text-primary mb-4">Hızlı Bağlantı</h3>
                <div className="d-flex flex-column justify-content-start">
                <a> <NavLink to="/" className="text-white">Anasayfa</NavLink></a>
                  <a> <NavLink to="/About" className="text-white">Hakkımızda</NavLink></a>
                  <a> <NavLink to="/Publish" className="text-white">Dijital Kaynak</NavLink></a>
                  <a> <NavLink to="/Contact" className="text-white">İletişim</NavLink></a>
                  <a> <NavLink to="https://www.instagram.com/professionalacademycenter" target="_blank" className="text-white">Instagram</NavLink></a>
                  <a> <NavLink to="https://wa.me/5462801884" className="text-white">Whatsapp</NavLink></a>

                </div>
              </div>

              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1553293.076832151!2d30.598398329941947!3d40.50234627119469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4080710a65301729%3A0x151a3f7e5217b1af!2sPAC%20Academy!5e0!3m2!1str!2str!4v1696641834881!5m2!1str!2str" width="350" height="300" style={{ border: 0 }} position="text-center" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div
              className="container-fluid pt-5"
            >
              <p className="m-0 text-center text-white">
                &copy;
                <a className="text-primary font-weight-bold">PAC Akademi</a>.
                Tüm Hakları Saklıdır.

                <br />designed by
                <a> <Link to="https://404yz.com" target="_blank">404yz </Link>
                </a>
              </p>
            </div>
          </div><a href="#" className="btn btn-primary p-3 back-to-top"
          ><i className="fa fa-angle-double-up"></i></a>
</main>

    }
}

export default Home;